import { graphql } from "gatsby";
import React from "react";
import { TideItemForUsp } from "../../types";
import UspItem from "./usp-item";

interface UspGridProps {
  usps: TideItemForUsp[];
}

const UspGrid: React.FC<UspGridProps> = ({ usps }) => (
  <div className="usp-grid">
    {usps.map((usp) => (
      <UspItem
        icon={usp.content?.general?.icon ?? undefined}
        text={usp.content?.general?.text ?? undefined}
        key={usp.id}
      />
    ))}
  </div>
);

export const query = graphql`
  fragment uspGroupFields on TideItemForUspGroup {
    id
    content {
      general {
        title
      }
    }
    childItems {
      id
      itemId
      templateName
      ... on TideItemForUsp {
        content {
          general {
            icon
            text
          }
        }
      }
    }
  }
`;

export default UspGrid;
