import React from "react";
import Icon from "../icon";

interface UspItemProps {
  icon?: string;
  text?: string;
}

const UspItem: React.FC<UspItemProps> = ({ icon, text }) => (
  <div className="usp-grid__item">
    <Icon name={icon ?? ""} />
    {text}
  </div>
);

export default UspItem;
