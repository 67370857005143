import { isEmpty } from "lodash";
import React from "react";
import { TideItemForNavigationSection } from "../../types";
import { getHrefFromNavigationLink, getChildNavigationLinks } from "../../utils";
import Link from "../link";

interface FooterNavigationSectionProps {
  navigationSection: TideItemForNavigationSection;
}

const FooterNavigationSection: React.FC<FooterNavigationSectionProps> = ({ navigationSection }) => {
  const generalContent = navigationSection?.content?.general;
  const hasSectionLink =
    (!isEmpty(generalContent?.path) || !isEmpty(generalContent?.url)) &&
    !isEmpty(generalContent?.text);

  return (
    <div className="column">
      {getChildNavigationLinks(navigationSection).map((navigationLink) => (
        <Link
          href={getHrefFromNavigationLink(navigationLink)}
          internal={!isEmpty(navigationLink.content?.general?.path)}
          title={navigationLink.content?.general?.title ?? ""}
          className="link link--plain"
          key={navigationLink.id}
        >
          {navigationLink.content?.general?.title}
        </Link>
      ))}
      {hasSectionLink && (
        <Link
          href={
            (isEmpty(generalContent?.path)
              ? `/${generalContent?.url}`
              : `/${generalContent?.path}`) ?? ""
          }
          internal={!isEmpty(generalContent?.path)}
          title={generalContent?.text ?? ""}
          className="link"
        >
          {generalContent?.text}
        </Link>
      )}
    </div>
  );
};

export default FooterNavigationSection;
