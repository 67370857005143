import React from "react";
import UspGrid from "../usp-grid";
import logoImg from "../../images/travelworld_logo.png";
import footerImg from "../../images/travelworld_footer.jpg";
import { getChildNavigationSections, getChildUsps } from "../../utils";
import FooterNavigationSection from "./footer-navigation-section";
import Social from "../social";
import Legal from "../legal";
import Link from "../link";
import FooterNewsletterForm from "./footer-newsletter-form";
import {
  TideItemForNavigationSection,
  TideItemForNewsletterForm,
  TideItemForUspGroup,
} from "../../types";
import { useI18next } from "gatsby-plugin-react-i18next";

interface FooterProps {
  footerNavigationSection: TideItemForNavigationSection;
  socialNavigationSection: TideItemForNavigationSection;
  legalNavigationSection: TideItemForNavigationSection;
  uspGroup: TideItemForUspGroup;
  address: string;
  phone: string;
  email: string;
  newsletterForm: TideItemForNewsletterForm;
}

const Footer: React.FC<FooterProps> = ({
  footerNavigationSection,
  socialNavigationSection,
  legalNavigationSection,
  uspGroup,
  address,
  phone,
  email,
  newsletterForm,
}) => {
  const { t } = useI18next();

  return (
    <footer className="footer">
      <div className="footer__usps">
        <img src={footerImg} alt="Travelworld" className="footer__usps-media" />
        <div className="footer__usps-content">
          <h2 className="footer__usps-heading">{uspGroup?.content?.general?.title}</h2>
          <UspGrid usps={getChildUsps(uspGroup)} />
        </div>
      </div>

      <div className="footer__frame">
        <div className="footer__frame-content">
          <div className="footer__frame-column">
            <Link href="/" internal title="Home" className="logo">
              <img src={logoImg} alt="Travelworld" className="logo__media" />
            </Link>
            <strong>Travelworld</strong>
            <div dangerouslySetInnerHTML={{ __html: address }}></div>
            <br />
            <ul className="list list--inline">
              <li className="list__item">
                <strong>T</strong>
              </li>
              <li className="list__item">
                <a
                  href={`tel:${phone}`}
                  title={t("CALL_US", { phone })}
                  className="link link--plain"
                >
                  {phone}
                </a>
              </li>
            </ul>

            <ul className="list list--inline">
              <li className="list__item">
                <strong>E</strong>
              </li>
              <li className="list__item">
                <a
                  href={`mailto:${email}?subject=Travelworld%20info`}
                  title={t("MAIL_US", { email })}
                  className="link link--plain"
                >
                  {email}
                </a>
              </li>
            </ul>
            <Social socialNavigationSection={socialNavigationSection} />
          </div>
          <div className="footer__frame-column footer__frame-column--products">
            {getChildNavigationSections(footerNavigationSection).map((navigationSection) => (
              <FooterNavigationSection
                key={navigationSection.id}
                navigationSection={navigationSection}
              />
            ))}
          </div>
          <div className="footer__frame-column">
            <FooterNewsletterForm config={newsletterForm} />
          </div>
        </div>
        <Legal legalNavigationSection={legalNavigationSection} showCookiePreferencesLink />
      </div>
    </footer>
  );
};

export default Footer;
