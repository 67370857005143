import React, { useState } from "react";

interface NotificationBarProps {
  text: string;
  icon?: string;
  backgroundColor?: string;
}

const NotificationBar: React.FC<NotificationBarProps> = ({ text, icon, backgroundColor }) => {
  const [hideNotificationBar, setHideNotificationBar] = useState(false);

  if (typeof sessionStorage !== "undefined") {
    const sessionStorageValue = sessionStorage.getItem("hideNotificationBar");
    if (
      sessionStorageValue &&
      JSON.parse(sessionStorageValue) &&
      JSON.parse(sessionStorageValue) != hideNotificationBar
    ) {
      setHideNotificationBar(true);
    }
  }

  const handleClick = () => {
    if (typeof sessionStorage !== "undefined") {
      sessionStorage.setItem("hideNotificationBar", JSON.stringify(true));
    }
    setHideNotificationBar(true);
  };

  return !hideNotificationBar ? (
    <div className="notification" style={{ backgroundColor: backgroundColor }}>
      <div className="notification__copy">
        <i className={icon}></i>
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
      </div>
      <a className="notification__close" onClick={() => handleClick()}></a>
    </div>
  ) : null;
};

export default NotificationBar;
