import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { TideItemForNavigationLink } from "../../types";
import { buildClassName } from "../../utils";
import Link from "../link";

interface StickyBarProps {
  navigationLinks?: TideItemForNavigationLink[];
  actionsComponent?: React.ReactElement;
}

const StickyBar: React.FC<StickyBarProps> = ({ navigationLinks, actionsComponent }) => {
  const [active, setActive] = useState<boolean>(false);

  const handleScroll: EventListener = () => {
    if (typeof window !== "undefined" && typeof document !== "undefined") {
      const navigation = document.querySelector(".navigation");
      const offset = navigation?.clientHeight ?? 0;
      setActive(window.scrollY >= offset);
    }
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  });

  return (
    <section className={buildClassName(["sticky-bar", active && "sticky-bar--active"])}>
      <div className="sticky-bar-content">
        <div className="link-items">
          {navigationLinks?.map((link) => (
            <Link
              key={link.id}
              href={
                (isEmpty(link.content?.general?.path)
                  ? link.content?.general?.url
                  : link.content?.general?.path) ?? undefined
              }
              internal={!isEmpty(link.content?.general?.path)}
              className="link-item"
              title={link.content?.general?.title ?? undefined}
            >
              {link.content?.general?.title ?? undefined}
            </Link>
          ))}
        </div>
        {actionsComponent && <div className="sticky-bar__actions">{actionsComponent}</div>}
      </div>
    </section>
  );
};

export default StickyBar;
