import React, { useState } from "react";

import Link from "../link";
import { TideItemForNewsletterForm } from "../../types";
import { buildClassName } from "../../utils";
import { isEmpty } from "lodash";
import { Trans, useI18next } from "gatsby-plugin-react-i18next";

interface FooterNewsletterFormProps {
  config: TideItemForNewsletterForm;
}

const formStates = {
  DRAFT: "DRAFT",
  PENDING: "PENDING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

const FooterNewsletterForm: React.FC<FooterNewsletterFormProps> = ({ config }) => {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [consent, setConsent] = useState<boolean>(false);
  const { t, language } = useI18next();

  const [formState, setFormState] = useState<string>(formStates.DRAFT);
  const [touched, setTouched] = useState<{ [key: string]: boolean }>({});

  const isValidEmail = /\S+@\S+\.\S+/.test(email);

  const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.name === "firstName") {
      setFirstName(event.target.value);
    }

    if (event.target.name === "lastName") {
      setLastName(event.target.value);
    }

    if (event.target.name === "email") {
      setEmail(event.target.value);
    }

    if (event.target.name === "consent") {
      setConsent(event.target.checked);
    }
  };

  const handleBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
    setTouched(
      Object.assign({}, touched, {
        [event.target.name]: true,
      })
    );
  };

  const handleSubmit: React.FormEventHandler = async (event) => {
    event.preventDefault();

    setTouched({
      firstName: true,
      lastName: true,
      email: true,
      consent: true,
    });

    if (!isEmpty(firstName) && !isEmpty(lastName) && isValidEmail && consent === true) {
      setFormState(formStates.PENDING);

      const response = await fetch(`${process.env.TIDE_HOST}/api/web/crmcontact`, {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Api-Key": process.env.API_KEY ?? "",
        },
        body: JSON.stringify({
          firstName: firstName,
          lastName: lastName,
          email: email,
          avoidCreatingCrmContact: !consent,
          tags: [config.content?.contactDetails?.tag?.tideId],
          confirmationEmailTemplate: config.content?.externalMail?.templateConfirmationMail?.tideId,
          languageCode: language,
        }),
      });

      if (response.ok) {
        setFormState(formStates.SUCCESS);
      } else {
        setFormState(formStates.ERROR);
      }
    }
  };

  return (
    <div className="footer__newsletter">
      <h5>{formState === formStates.SUCCESS ? t("YOU_ARE_SIGNED_UP") : t("KEEP_POSTED")}</h5>
      {formState !== formStates.SUCCESS && (
        <form
          className="form form--newsletter"
          name="form-newsletter"
          id="form-newsletter"
          onSubmit={handleSubmit}
          noValidate
        >
          <div className="form__row">
            <div
              className={buildClassName([
                "form__group",
                touched.firstName && isEmpty(firstName) && "form__group--error",
              ])}
            >
              <input
                type="text"
                name="firstName"
                className="form__input"
                value={firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t("FIRST_NAME")}
                required
              />
            </div>
          </div>
          <div className="form__row">
            <div
              className={buildClassName([
                "form__group",
                touched.lastName && isEmpty(lastName) && "form__group--error",
              ])}
            >
              <input
                type="text"
                name="lastName"
                className="form__input"
                value={lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t("LAST_NAME")}
                required
              />
            </div>
          </div>
          <div className="form__row">
            <div
              className={buildClassName([
                "form__group",
                touched.email && !isValidEmail && "form__group--error",
              ])}
            >
              <input
                type="email"
                className="form__input"
                name="email"
                value={email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={t("YOUR_EMAIL_ADDRESS")}
                required
              />
            </div>
          </div>
          <div className="form__row">
            <div className="form__group">
              <button type="submit" className="cta cta--secondary" title={t("SIGN_UP")}>
                {t("SIGN_UP")}
              </button>
            </div>
          </div>
          <div className="form__row form__row--disclaimer">
            <div
              className={buildClassName([
                "form__group",
                touched.consent && consent === false && "form__group--error",
              ])}
            >
              <div className="disclaimer-text">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    name="consent"
                    checked={consent}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="checkbox__input"
                  />
                  <span>
                    <Trans i18nKey="BY_SIGNING_UP_I_ACCEPT_THE_TERMS">
                      Door me in te schrijven ga ik akkoord met het verwerken van mijn
                      persoonsgegevens, die beschreven staan in de{" "}
                      <Link
                        target="_blank"
                        rel="noopener"
                        internal
                        href={`/${config?.content?.form?.privacyPage}`}
                        title={t("READ_THE_DISCLAIMER")}
                        className="link link--plain"
                      >
                        privacy disclaimer
                      </Link>
                      .
                    </Trans>
                  </span>
                </label>
              </div>
            </div>
          </div>
        </form>
      )}
      {formState === formStates.SUCCESS && (
        <div className="form-state form-state--success">
          <div className="form">
            <div className="form__region">
              <div className="form__row">
                <div className="form__group form__group--success-message">
                  <p>
                    {t("SUBSCRIPTION_RECEIVED_SUCCESSFULL")}
                    <br />
                    {t("SUBSCRIPTION_RECEIVED_SUCCESSFULL_DESCRIPTION")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FooterNewsletterForm;
